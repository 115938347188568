<template>
  <div class="rightLink">
    <div class="bottomDiv">
      <div class="title">设备管理</div>
      <div class="formDiv">
        <el-form label-position="right" label-width="100px">
          <el-row :gutter="20">
            <el-col :span="7">
              <el-form-item label="设备编号:">
                <el-input style="width: 100%" v-model="FormData.device_id" placeholder="请输入设备编号" clearable></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="1"></el-col> -->
            <el-col v-show="isadmin" :span="7">
              <el-form-item label="运营商:">
                <el-select v-model="FormData.username" placeholder="请选择运营商" style="width: 100%" clearable>
                  <el-option v-for="aa in users" :key="aa.username" :value="aa.username" :label="aa.username" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="规格类型:">
                <el-select style="width: 100%" v-model="FormData.Specification_type" placeholder="请选择规格类型" clearable>
                  <el-option v-for="item in Specifs" :key="item.value" :value="item.value" :label="item.label" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <!-- <el-col  v-show="isadmin" :span="1"></el-col> -->
            <el-col :span="7">
              <el-form-item label="设备状态:">
                <el-select v-model="FormData.state" placeholder="请选择设备状态" clearable style="width: 100%">
                  <el-option v-for="item in stateList" :key="item" :value="item.value" :label="item.label" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="开通时间" class="time_pick">
                <el-date-picker style="width: 100%; height: 40px" v-model="FormData.actionTime" type="daterange"
                  unlink-panels range-separator=" - " start-placeholder="开始时间" end-placeholder="结束时间" size="small"
                  format="YYYY-MM-DD" value-format="YYYY-MM-DD">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="7" v-show="isShowCarType">
              <el-form-item label="站点类型:">
                <el-select style="width: 100%" v-model="FormData.type_id" placeholder="请选择站点类型" clearable>
                  <el-option v-for="car in carList" :key="car.value" :value="car.value" :label="car.label" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <div style="margin-left: 30px" class="BtnSearch" @click="searchBtn()">
                查询
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 表格部分 -->
      <div class="tableDiv">
        <el-table ref="multipleTableRef" :data="tableData" border v-loading="loading" height="480px"
          style="width: 96%; margin: 20 auto; overflow-y: auto" @row-click="sendFault">
          <el-table-column label="序号" type="index" align="center" width="60">
            <template #default="{ $index }">
              {{ (currentPage - 1) * pageSize + $index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="设备编号" prop="device_id" width="130" />
          <el-table-column label="运营商" prop="username" />
          <el-table-column label="站点名称" prop="station_name" width="160" />
          <el-table-column label="站点类型" prop="type_id">
            <template #default="scope">
              <span v-if="scope.row.type_id == 2">电单车</span>
              <span v-if="scope.row.type_id == 3">直流桩</span>
              <span v-if="scope.row.type_id == 4">交流桩</span>
            </template>
          </el-table-column>
          <el-table-column label="设备状态" prop="state">
            <template #default="scope">
              <span></span>
              <span v-if="scope.row.state == '空闲'" style="color: limegreen">空闲</span>
              <span v-if="scope.row.state == '故障'" style="color: red">故障</span>
              <span v-if="scope.row.state == '充电'" style="color: orange">充电中</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="故障详情" prop="breakdown" /> -->
          <!-- <el-table-column label="网络状态" prop="device_state" /> -->
          <el-table-column label="网络状态" prop="device_state">
            <template #default="scope">
              <span></span>
              <span v-if="scope.row.device_state == '正常'" style="color: green">正常</span>
              <span v-if="scope.row.device_state == '离线'" style="color: red">离线</span>
            </template>
          </el-table-column>
          <el-table-column label="生产厂商" prop="manufacturer" />
          <el-table-column label="规格类型" prop="Specification_type">
            <template #default="scope">
            <span v-if="scope.row.Specification_type == 0">单路充电</span>
              <span v-if="scope.row.Specification_type == 1">双路充电</span>
            </template>
          </el-table-column>
          <el-table-column label="所属省" prop="province" />
          <el-table-column label="所属市" prop="city" />
          <el-table-column label="所属县区" prop="county" />
          <el-table-column label="详细地址" prop="community" width="150" />
          <el-table-column label="功率" prop="wx_id" />
          <el-table-column label="端口数量" prop="portnuber" />
          <el-table-column label="端口A状态" prop="porta" width="90" />
          <el-table-column label="端口B状态" prop="portb" width="90" />

          <el-table-column label="端口A使用率(%)" prop="usage_rate_one" width="130" />
          <el-table-column label="端口B使用率(%)" prop="usage_rate_two" width="130" />
          <el-table-column label="信号强度" prop="wx_id" />
          <el-table-column label="设备温度(℃)" prop="wx_id" width="110" />
          <el-table-column label="计费模板" prop="price_id">
            <template #default="scope">
              {{ getTemplateName(scope.row.price_id) }}
            </template>
          </el-table-column>
          <el-table-column label="计费模板更新时间" prop="update_time" :formatter="formatDate" width="160" />
          <el-table-column label="开通时间" prop="opening_time" width="160" />
          <el-table-column label="安装时间" prop="opening_time" width="160" />
          <el-table-column label="操作" width="250" align="center" fixed="right">
            <template #default="scope">
              <!-- <div class="BtnSearch1 BtnSearch" @click="openCharg(scope.$index, scope.row)" >
                开启充电
              </div>
              <div class="BtnSearch1 BtnSearch" @click="offCharg(scope.$index, scope.row)" >
                关闭充电
              </div> -->
              <div class="btntext">
                <el-link target="_blank" @click.stop="handleEdit(scope.$index, scope.row)">编辑
                </el-link>
                <el-link target="_blank" type="warning" @click.stop="handleInit(scope.$index, scope.row)">初始化
                </el-link>
                <el-link type="primary" @click.stop="Unbound(scope.$index, scope.row)">解绑
                </el-link>
                <el-link type="success" @click.stop="handleerwm(scope.$index, scope.row)" v-if="scope.row.type_id != 2">下发二维码
                </el-link>
                <!-- <el-link type="warning">下发二维码</el-link> -->
                <el-link type="info" @click.stop="showerwm(scope.$index, scope.row)">查看二维码</el-link>
                <el-link type="info" @click.stop="confignum(scope.$index, scope.row)" v-if="scope.row.type_id != 2">配置参数</el-link>
                <el-link type="warning" @click.stop="handlclose(scope.$index, scope.row)" v-if="scope.row.type_id != 2">关闭充电</el-link>
                <el-link type="primary" @click.stop="handlreset(scope.$index, scope.row)" v-if="scope.row.type_id != 2">重启</el-link>
                <el-link type="danger" v-show="scope.row.is_abnormity == 0"
                  @click.stop="handleStop(scope.$index, scope.row)">临停</el-link>
                <el-link type="danger" v-show="scope.row.is_abnormity == 1"
                  @click.stop="handleright(scope.$index, scope.row)">恢复使用</el-link>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination :current-page="currentPage" :page-size="pageSize" :small="small"
            layout="total, slot, prev, pager, next, jumper" :total="total" @size-change="handleSizeChange"
            @current-change="handleCurrentChange">
            <template #default>
              <span class="el-pagination__total">{{ Math.ceil(total / pageSize) }} 页 </span>
            </template>
          </el-pagination>
        </div>
      </div>

      <!-- 编辑 -->
      <div v-dialogdrag>
        <el-dialog v-model="Dialog.editLevy" title="编辑" width="820px" :before-close="closeDialog" class="dialog">
          <div class="formDiv1">
            <el-form ref="FormRules" :rules="rules" :model="FormData" label-position="right" label-width="100px">
              <el-row :gutter="20">
                <el-col :span="2"></el-col>
                <el-col :span="9">
                  <el-form-item label="设备编号:">
                    <el-input disabled v-model="Dialog.DialogData.device_id" placeholder="请输入站点名称"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="9">
                  <el-form-item label="选择充电站:">
                    <el-select style="width: 100%" v-model="Dialog.DialogData.station_name">
                      <el-option v-show="type_id2" v-for="item in stations2" :key="item" :value="item.station_name"
                        :label="item.station_id" />
                      <el-option v-show="type_id4" v-for="item in stations4" :key="item" :value="item.station_name"
                        :label="item.station_id" />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="2"></el-col>
                <el-col :span="9">
                  <el-form-item label="生产厂商:">
                    <el-input v-model="Dialog.DialogData.manufacturer" placeholder="请输入生产厂商"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="9">
                  <el-form-item label="选择规格类型:">
                    <el-select style="width: 100%" v-model="Dialog.DialogData.Specification_type">
                      <el-option v-for="item in Specifs" :key="item" :value="item.value" :label="item.label" />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="2"></el-col>
                <el-col :span="9">
                  <el-form-item label="选择省份:">
                    <el-select style="width: 100%" v-model="Dialog.DialogData.province" placeholder="请选择省"
                      @change="handleProvinceChange">
                      <el-option v-for="provinces in provinceList" :key="provinces.value" :value="provinces.label"
                        :label="provinces.label" />
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="9">
                  <el-form-item label="选择市:">
                    <el-select style="width: 100%" v-model="Dialog.DialogData.city" placeholder="请选择市"
                      @change="handleCityChange">
                      <el-option v-for="citys in cityList[0]" :key="citys.value" :value="citys.label">{{ citys.label
                        }}</el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="2"></el-col>
                <el-col :span="9">
                  <el-form-item label="选择区/县:">
                    <el-select style="width: 100%" v-model="Dialog.DialogData.county" placeholder="请选择区/县">
                      <el-option v-for="countys in countyList[0]" :key="countys.value" :value="countys.label">{{
                        countys.label
                      }}</el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="9">
                  <el-form-item label="详细地址:">
                    <el-input v-model="Dialog.DialogData.community" placeholder="请输入站点名称"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <template #footer>
            <span class="dialog-footer">
              <el-button class="Btn" @click="closeDialog">取消</el-button>
              <el-button class="Btn" @click="submitDialog">提交</el-button>
            </span>
          </template>
        </el-dialog>
      </div>
      <div v-dialogdrag>
        <el-dialog v-model="Dialog1.editLevy" width="620px" height="400px" :before-close="closeDialog1" class="dialog">
          <el-radio-group v-show="isshow" v-model="radio" @change="radio1">
            <el-radio :label="0" name="0">单枪</el-radio>
            <el-radio :label="1" name="1">双枪</el-radio>
          </el-radio-group>
          <div style="
              display: flex;
              justify-content: space-evenly;
              width: 580px;
              text-align: center;
            ">
            <div v-show="Dialog1.DialogData.type_id == 3 || Dialog1.DialogData.type_id == 4">
              <div id="qrcode1" style="margin-top: 10px" ref="qrCodeUrl1"></div>
              <p style="margin-top: 4px; font-size: 18px">
                {{ Dialog1.DialogData.device_id }}01
              </p>
            </div>
            <div v-show="(Dialog1.DialogData.type_id == 3 || Dialog1.DialogData.type_id == 4) && portnuber == 2">
              <div id="qrcode2" style="margin-top: 10px" ref="qrCodeUrl2"></div>
              <p style="margin-top: 4px; font-size: 18px">
                {{ Dialog1.DialogData.device_id }}02
              </p>
            </div>
            <div v-show="Dialog1.DialogData.type_id == 2">
              <div id="qrcode3" style="margin-top: 10px" ref="qrCodeUrl3"></div>
              <p style="margin-top: 4px; font-size: 18px">
                {{ Dialog1.DialogData.device_id }}
              </p>
            </div>
          </div>
          <template #footer>
            <span v-show="isshow" class="dialog-footer">
              <el-button class="Btn" @click="submitDialog1">确认</el-button>
            </span>
          </template>
        </el-dialog>
      </div>
      <!-- 关闭充电 -->
      <div v-dialogdrag>
        <el-dialog v-model="Dialog2.editLevy" title="关闭充电" width="820px" :before-close="closeDialog" class="dialog">
          <div class="formDiv1">
            <el-form ref="FormRules" :rules="rules" :model="FormData2" label-position="right" label-width="100px">
              <el-row :gutter="20">
                <el-col :span="2"></el-col>
                <el-col :span="9">
                  <el-form-item label="设备编号:">
                    <el-input disabled v-model="Dialog2.DialogData.device_id"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="9">
                  <el-form-item label="设备类型:">
                    <el-input disabled v-model="Dialog2.DialogData.type_id"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="2"></el-col>

                <el-col :span="9">
                  <el-form-item label="选择规端口号:">
                    <el-select style="width: 100%" v-model="Dialog2.DialogData.sockert">
                      <el-option v-for="item in ports" :key="item" :value="item.label" :label="item.label" />
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <template #footer>
            <span class="dialog-footer">
              <el-button class="Btn" @click="closeDialog">取消</el-button>
              <el-button class="Btn" @click="submitDialog2">提交</el-button>
            </span>
          </template>
        </el-dialog>
      </div>
      <!-- 参数配置 -->
      <div v-dialogdrag>
        <el-dialog v-model="Dialog3.editLevy" title="参数配置" width="820px" :before-close="closeDialog" class="dialog">
          <div class="formDiv1">
            <el-form ref="FormRules" :rules="rules" :model="FormData3" label-position="right" label-width="100px">
              <el-row :gutter="20">
                <el-col :span="2"></el-col>
                <el-col :span="10">
                  <el-form-item label="设备编号:">
                    <el-input disabled v-model="Dialog3.DialogData.device_id"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item label="最大功率:">
                    <el-input type="number" v-model="Dialog3.DialogData.max_power"><template
                        #append>W</template></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="2"></el-col>
                <el-col :span="10">
                  <el-form-item label="充满自停功率:">
                    <el-input type="number" v-model="Dialog3.DialogData.automatic_stop"><template
                        #append>W</template></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="10">
                  <el-form-item label="充满延时时间:">
                    <el-input type="number" v-model="Dialog3.DialogData.delayed_time"><template
                        #append>分钟</template></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="2"></el-col>

                <el-col :span="10">
                  <el-form-item label="卡是否加密:">
                    <el-radio-group v-model="radio">
                      <el-radio :label="1">加密</el-radio>
                      <el-radio :label="0">不加密</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <template #footer>
            <span class="dialog-footer">
              <el-button class="Btn" @click="closeDialog">取消</el-button>
              <el-button class="Btn" @click="submitDialog3">提交</el-button>
            </span>
          </template>
        </el-dialog>
      </div>
      <!-- 重启 -->
      <div v-dialogdrag>
        <el-dialog v-model="Dialog5.editLevy" title="参数配置" width="820px" :before-close="closeDialog" class="dialog">
          <div class="formDiv1">
            <el-form ref="FormRules" :rules="rules" :model="FormData5" label-position="right" label-width="100px">
              <el-row :gutter="20">
                <el-col :span="2"></el-col>

                <el-col :span="10">
                  <el-form-item label="重启时间:">
                    <el-radio-group v-model="radio2">
                      <el-radio :label="1">立即重启</el-radio>
                      <el-radio :label="2">空闲时重启</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
          <template #footer>
            <span class="dialog-footer">
              <el-button class="Btn" @click="closeDialog">取消</el-button>
              <el-button class="Btn" @click="submitDialog5">提交</el-button>
            </span>
          </template>
        </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
// import { ref } from 'vue'
import { onMounted, reactive, toRefs, onUnmounted, watch } from "vue-demi";
import {
  sel_device,
  upd_device,
  stop_charge,
  qr_code,
  sel_station,
  unbind_device1,
  unbind_device2,
  operator_data,
  simple_stop_charge,
  temporary_closure,
  device_setup,
  device_setup_up,
  restartdevice,
  sel_temp,
  pcmalfunction
} from "@/request/api";
import { ElMessageBox, ElMessage } from "element-plus";
import ProvinceCityCountry from "../../assets/json/address";
import { regionData } from "element-china-area-data";
import { useRoute, useRouter } from "vue-router";
// import axios from "axios";
import QRCode from "qrcodejs2";
import { carList } from '@/common/common.js'

export default {
  name: "EditArea",
  data() {
    return {
      Dialog1: {
        editLevy: false,
        DialogData: {},
      },
      isshow: false,
      radio: 0,

      portnuber: 1,
    };
  },
  methods: {
    radio1(a) {
      console.log(a, "0000000type");

      document.getElementById("qrcode1").innerHTML = "";
      document.getElementById("qrcode2").innerHTML = "";
      document.getElementById("qrcode3").innerHTML = "";
      // this.Dialog1.DialogData = {}
      this.portnuber = a + 1;
      console.log(this.portnuber, "this.portnuberthis.portnuber");
      this.getErwm();
    },
    showerwm(index, row) {
      this.Dialog1.editLevy = true;
      this.Dialog1.DialogData = row;
      if (row.type_id == 3 || row.type_id == 4) {
        if (row.portnuber !== null) {
          this.isshow = false;
          this.portnuber = row.portnuber;
        } else {
          this.isshow = true;
        }
      }

      this.getErwm();
    },
    getErwm() {
      console.log(this.portnuber, "点击显示二维码！");
      if (this.Dialog1.DialogData.type_id == 3 || this.Dialog1.DialogData.type_id == 4) {
        setTimeout(() => {
          var qrcode1 = new QRCode(this.$refs.qrCodeUrl1, {
            text: `http://materials-china.com?No=${this.Dialog1.DialogData.device_id}01`, // 需要转换为二维码的内容
            width: 200,
            height: 200,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
          });
          var qrcode2 = new QRCode(this.$refs.qrCodeUrl2, {
            text: `http://materials-china.com?No=${this.Dialog1.DialogData.device_id}02`, // 需要转换为二维码的内容
            width: 200,
            height: 200,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
          });
        }, 1);
      } else {
        console.log("两轮");
        setTimeout(() => {
          var qrcode3 = new QRCode(this.$refs.qrCodeUrl3, {
            // text: 'https://blog.csdn.net/weixin_42601136', // 需要转换为二维码的内容
            text: `http://materials-china.com?No=${this.Dialog1.DialogData.device_id}`, // 需要转换为二维码的内容
            width: 200,
            height: 200,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
          });
        }, 1);
      }
    },
    closeDialog1() {
      this.Dialog1.editLevy = false;
      document.getElementById("qrcode1").innerHTML = "";
      document.getElementById("qrcode2").innerHTML = "";
      document.getElementById("qrcode3").innerHTML = "";
      this.Dialog1.DialogData = {};
    },

    submitDialog1() {
      this.Dialog1.editLevy = false;
      document.getElementById("qrcode1").innerHTML = "";
      document.getElementById("qrcode2").innerHTML = "";
      document.getElementById("qrcode3").innerHTML = "";
      this.Dialog1.DialogData = {};
    },
  },

  setup() {
    const route = useRoute();
    const router = useRouter();

    const data = reactive({
      isShowCarType: false,
      //表单数据
      FormData: {
        actionTime: ["", ""],
      },
      FormData2: {},
      FormData3: {},
      FormData5: {},
      radio: 1,
      radio2: 1,
      isadmin: false,
      users: [],
      tableData: [
        {
          serial_number: "YH001",
          user_name: "张三",
        },
      ],
      carList: carList,
      car: "",
      provinceData: ProvinceCityCountry.address,
      // options: regionData,
      // selectedOptions: "",
      // 省市县
      regionData,
      provinceList: [],
      cityList: [],
      countyList: [],
      province: "",
      city: "",
      county: "",

      //   弹框
      Dialog: {
        editLevy: false,
        DialogData: {},
      },
      Dialog2: {
        editLevy: false,
        DialogData: {},
      },
      Dialog3: {
        editLevy: false,
        DialogData: {},
      },
      Dialog5: {
        editLevy: false,
        DialogData: {},
      },
      loading: false,
      //   分页
      currentPage: 1,
      pageSize: 20,
      total: 0,
      checked: 0,
      small: true,
      stations2: [],
      stations4: [],
      type_id2: false,
      type_id4: false,
      Specifs: [
        {
          value: 0,
          label: "单路充电",
        },
        {
          value: 1,
          label: "双路充电",
        },
      ],
      ports: [
        {
          value: 0,
          label: "01",
        },
        {
          value: 1,
          label: "02",
        },
      ],
      stateList: [
        { label: "空闲", value: "空闲" },
        { label: "充电中", value: "充电" },
        { label: "故障", value: "故障" },
      ],
      temps: [],
    });

    const getPathParam = (path, name) => {
      // example path: /device_manage/charg_manage-type=2-other=xxx
      const arr = path.split('-')
      const str = arr.find(item => item.includes(name))
      let value = str.split('=')[1]
      if(value === 'undefined') value = undefined
      return value
    }
    data.FormData.type_id = getPathParam(route.path, 'type')
    data.isShowCarType = data.FormData.type_id == undefined

    watch(() => route.path, (newVal, oldVal) => {
      if (newVal.includes('charg_manage-type')) {
        data.FormData.type_id = getPathParam(route.path, 'type')
        data.isShowCarType = data.FormData.type_id == undefined
        getList()
      }
    })

    const closeDialog = () => {
      data.Dialog.editLevy = false;
      data.Dialog2.editLevy = false;
      data.Dialog3.editLevy = false;
      data.Dialog5.editLevy = false;
    };

    const searchBtn = () => {
      data.currentPage = 1;
      getList();
    };
    const formatDate = (row, column) => {
      // 获取单元格数据
      let data = row[column.property];
      if (data == null) {
        return null;
      }
      let now = new Date(data);
      let year =
        now.getFullYear() < 10 ? "0" + now.getFullYear() : now.getFullYear(); //得到年份
      let month =
        now.getMonth() + 1 < 10
          ? "0" + (now.getMonth() + 1)
          : now.getMonth() + 1; //得到月份
      let date = now.getDate() < 10 ? "0" + now.getDate() : now.getDate(); //得到日期
      let hour = now.getHours() < 10 ? "0" + now.getHours() : now.getHours(); //得到小时数
      let minute =
        now.getMinutes() < 10 ? "0" + now.getMinutes() : now.getMinutes(); //得到分钟数
      let seconds =
        now.getSeconds() < 10 ? "0" + now.getSeconds() : now.getSeconds(); //得到秒数

      return (
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        seconds
      );
    };
    const offCharg = (index, row) => {
      const dataa = {
        device_id: row.device_id,
      };
      stop_charge(dataa).then((res) => {
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            ElMessage.success("已开启充电");
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };
    const handleEdit = (index, row) => {
      getStation2();
      getStation4();
      data.Dialog.editLevy = true;
      data.Dialog.DialogData = JSON.parse(JSON.stringify(row));
      if (row.type_id == 2) {
        data.type_id2 = true;
        data.type_id4 = false;
      } else if (row.type_id == 4) {
        data.type_id4 = true;
        data.type_id2 = false;
      }
    };
    const handleerwm = (index, row) => {
      const dataa = {
        pile_crc: row.device_id,
      };
      qr_code(dataa).then((res) => {
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            ElMessage.success("下发成功");
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("下发失败");
        }
      });
    };

    const Unbound = (index, row) => {
      const data1 = reactive({
        device_id: row.device_id,
      });
      ElMessageBox.confirm("您确认要解绑设备吗?", "警告", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(data1, "data1data1");
          unbind_device1(data1).then((res) => {
            console.log(res, "0000000ressqq");
            if (res) {
              console.log(res, "解绑");
              data.loading = false;
              if (res.code == 200) {
                ElMessage.success("解绑成功");
                getList();
              } else {
                ElMessage.error(res.msg);
              }
            } else {
              ElMessage.error("解绑失败");
            }
          });
        })
        .catch(() => {
          ElMessage.error("解绑失败请重试");
        });
    };
    // 初始化
    const handleInit = (index, row) => {
      const data2 = {
        device_id: row.device_id,
      };
      ElMessageBox.confirm("您确认要初始化设备吗?", "警告", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        unbind_device2(data2).then((res) => {
          console.log(res, "0000000ress");
          if (res) {
            console.log(res, "初始化posttttttt");
            data.loading = false;
            if (res.code == 200) {
              ElMessage.success("初始化成功");
              getList();
            } else {
              ElMessage.error(res.msg);
            }
          } else {
            ElMessage.error("初始化失败");
          }
        });
      });
    };
    // 关闭充电
    const handlclose = (index, row) => {
      data.Dialog2.editLevy = true;
      data.Dialog2.DialogData = row;
    };
    const handlreset = (index, row) => {
      data.Dialog5.editLevy = true;
      data.Dialog5.DialogData = row;
    };
    const confignum = (index, row) => {
      const dataa = {
        device_id: row.device_id,
      };
      device_setup(dataa).then((res) => {
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            console.log(res, "ressssssssssss");
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("关闭失败");
        }
      });
      data.Dialog3.editLevy = true;
      data.Dialog3.DialogData = row;
    };
    const submitDialog2 = () => {
      const dataa = {
        device_id: data.Dialog2.DialogData.device_id,
        type_id: data.Dialog2.DialogData.type_id.toString(),
        sockert: data.Dialog2.DialogData.sockert,
      };
      simple_stop_charge(dataa).then((res) => {
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            ElMessage.success("关闭成功");
            submitData();
            getList();
            data.Dialog2.editLevy = false;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("关闭失败");
        }
      });
    };
    const submitDialog3 = () => {
      const dataa = {
        device_id: data.Dialog3.DialogData.device_id,
        max_power: parseInt(data.Dialog3.DialogData.max_power),
        automatic_stop: parseInt(data.Dialog3.DialogData.automatic_stop),
        delayed_time: parseInt(data.Dialog3.DialogData.delayed_time),
        card: data.radio,
      };
      device_setup_up(dataa).then((res) => {
        if (res) {
          console.log(res, "配置配置");
          data.loading = false;
          data.Dialog3.editLevy = false;
          // if (res.code == 200) {
          //   ElMessage.success("关闭成功")
          //   submitData();
          //   getList()
          //   data.Dialog2.editLevy = false;
          // } else {
          //   ElMessage.error(res.msg);
          // }
        } else {
          ElMessage.error("配置失败");
        }
      });
    };
    const submitDialog5 = () => {
      const dataa = {
        pile_crc: data.Dialog5.DialogData.device_id,
        code: data.radio2,
      };
      restartdevice(dataa).then((res) => {
        if (res) {
          console.log(res, "重启");
          data.loading = false;
          data.Dialog5.editLevy = false;
          // if (res.code == 200) {
          //   ElMessage.success("关闭成功")
          //   submitData();
          //   getList()
          //   data.Dialog2.editLevy = false;
          // } else {
          //   ElMessage.error(res.msg);
          // }
        } else {
          ElMessage.error("配置失败");
        }
      });
    };

    // 临停
    const handleStop = (index, row) => {
      const data2 = {
        device_id: row.device_id,
        code: 1,
      };
      ElMessageBox.confirm("您确认临时停用?", "警告", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        temporary_closure(data2).then((res) => {
          console.log(res, "0000000ress");
          if (res) {
            console.log(res, "初始化posttttttt");
            data.loading = false;
            if (res.code == 200) {
              ElMessage.success("操作成功");
              getList();
            } else {
              ElMessage.error(res.msg);
            }
          } else {
            ElMessage.error("操作失败");
          }
        });
      });
    };
    // 临停
    const handleright = (index, row) => {
      const data2 = {
        device_id: row.device_id,
        code: 0,
      };
      ElMessageBox.confirm("您确认恢复正常使用吗?", "警告", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        temporary_closure(data2).then((res) => {
          console.log(res, "0000000ress");
          if (res) {
            console.log(res, "初始化posttttttt");
            data.loading = false;
            if (res.code == 200) {
              ElMessage.success("操作成功");
              getList();
            } else {
              ElMessage.error(res.msg);
            }
          } else {
            ElMessage.error("操作失败");
          }
        });
      });
    };

    const submitDialog = () => {
      const dataa = data.Dialog.DialogData;
      upd_device(dataa).then((res) => {
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            ElMessage.success("编辑成功");
            submitData();
            getList();
            data.Dialog.editLevy = false;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("编辑失败");
        }
      });
    };

    const submitData = () => { };

    const getList = () => {
      let stDate;
      let endDate;
      if (data.FormData.actionTime) {
        stDate = data.FormData.actionTime[0]
          ? data.FormData.actionTime[0]
          : null;
        endDate = data.FormData.actionTime[1]
          ? data.FormData.actionTime[1]
          : null;
      } else if (
        data.FormData.actionTime === null ||
        data.FormData.actionTime === undefined ||
        data.FormData.actionTime === ""
      ) {
        stDate = null;
        endDate = null;
      }
      const dataa = {
        station_id: router.currentRoute
          ? router.currentRoute.value.query.station_id
          : "",
        operator_id: sessionStorage.getItem("userID"),
        username: data.FormData.username,
        device_id: data.FormData.device_id,
        type_id: data.FormData.type_id,
        start_execute_date: stDate,
        stop_execute_date: endDate,
        Specification_type: data.FormData.Specification_type,
        page: data.currentPage,
        state: data.FormData.state,
      };

      data.loading = true;
      sel_device(dataa).then((res) => {
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            data.tableData = res.data;
            data.total = res.count_data;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };

    const getStation2 = () => {
      const dataa = {
        operator_id: sessionStorage.getItem("userID"),
        type_id: 2,
      };
      sel_station(dataa).then((res) => {
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            data.stations2 = res.data;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("充电站获取失败");
        }
      });
    };

    const getStation4 = () => {
      const dataa = {
        operator_id: sessionStorage.getItem("userID"),
        type_id: 4,
      };
      sel_station(dataa).then((res) => {
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            data.stations4 = res.data;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("充电站获取失败");
        }
      });
    };

    const getUsername = () => {
      const username1 = localStorage.getItem("username");
      const users1 = [];
      operator_data().then((res) => {
        data.users = res;
        res.forEach((a) => {
          users1.push(a.username);
        });
        if (users1.indexOf(username1) == -1) {
          data.isadmin = true;
        } else {
          data.isadmin = false;
          data.FormData.username = username1;
        }
      });
    };

    const handleChange = (value) => { };

    const handleSizeChange = (val) => {
      data.pageSize = val;
    };
    const handleCurrentChange = (val) => {
      data.currentPage = val;
      getList();
      //
    };

    const initProvinceList = () => {
      // 初始化省份列表数据源
      regionData.forEach((tab) => {
        data.provinceList.push(tab);
      });
    };
    const handleProvinceChange = (val) => {
      data.cityList = [];
      data.countyList = [];
      data.province = val;
      data.FormData.city = "";
      data.FormData.county = "";
      initCityList();
    };

    const initCityList = () => {
      // 初始化城市列表数据源
      data.provinceList.forEach((tab1) => {
        if (tab1.label == data.province) {
          data.cityList.push(tab1.children);
        }
      });
    };

    const handleCityChange = (value1) => {
      data.countyList = [];
      data.city = value1;
      data.FormData.county = "";
      initCountyList();
    };

    const initCountyList = () => {
      // 初始化县/区列表数据源
      // 初始化城市列表数据源
      data.cityList[0].forEach((tab2) => {
        // tab2.forEach((tab3)=>{
        //   console.log(tab3,'tab3');
        //    if(tab3.label==this.selectedCity){
        //  this.countyList.push(tab3.children)
        // }
        // })
        if (tab2.label == data.city) {
          data.countyList.push(tab2.children);
        }
      });
    };

    const getTemps = () => {
      const aaa = {
        username: localStorage.getItem("username"),
      };
      sel_temp(aaa).then((res) => {
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            data.temps = res.data;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };
    const getTemplateName = (templateId) => {
      const template = data.temps.find((t) => t.id === templateId);
      return template ? template.price_name : "暂无模板"; // 如果找不到模板，返回'未知模板'
    };
    const sendFault = (column) => {
      if (column.being_processed == 0 && column.state == "故障") {
        ElMessageBox.confirm("确定生成一条故障单吗？", "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          pcmalfunction({ device_id: column.device_id, status: "其他", details: "", operator_id: column.operator_id, station_name: column.station_name }).then((res) => {
            if (res) {
              if (res.code == 200) {
                getList();
                ElMessage({
                  type: "success",
                  message: "操作成功",
                });
              } else {
                ElMessage.error(res.msg);
              }
            } else {
              ElMessage.error("操作失败");
            }
          });
        });
      }
    };

    onUnmounted(() => {
      sessionStorage.removeItem("state");
    });

    onMounted(() => {
      const states = sessionStorage.getItem("state");
      if (states) {
        if (states == 0) {
          data.FormData.state = "空闲";
        } else if (states == 1) {
          data.FormData.state = "充电";
        } else if (states == 2) {
          data.FormData.state = "故障";
        }
      }
      getList();
      getUsername();
      initProvinceList();
      getTemps();
    });
    return {
      ...toRefs(data),
      searchBtn,
      offCharg,
      handleEdit,
      handleerwm,
      Unbound,
      handleInit,
      handlclose,
      handlreset,
      confignum,
      handleStop,
      handleright,
      closeDialog,
      submitDialog,
      submitDialog2,
      submitDialog3,
      submitDialog5,
      submitData,
      getList,
      getStation2,
      getStation4,
      handleChange,
      handleSizeChange,
      handleCurrentChange,
      initProvinceList,
      handleProvinceChange,
      handleCityChange,
      initCityList,
      initCountyList,
      getUsername,
      formatDate,
      getTemplateName,
      sendFault
    };
  },
};
</script>
<style scoped>
.formDiv {
  margin-top: 10px;
  height: 120px;
  width: 1200px;
}

.formDiv1 {
  margin-top: 10px;
  height: 220px;
}

.bottomDiv {
  height: 100%;
}

.btn:focus {
  background-color: #03beb0;
  border-color: #03beb0;
}

.BtnSearch1 {
  float: left;
  width: 80px;
  margin: 0 4px;
}

.pagination {
  display: flex;
  justify-content: space-between;
  padding: 0 67px;
  margin-top: 10px;
}

.el-link {
  margin: 0 3px;
}
</style>
